// Return a object with the number of stars and the base price headline based on `basePriceHeadline`
// Ex: getBasePriceHeadlineValues(4) => { stars: '**', sentence: 'prix par personne calculé sur une base de 4 voyageurs.' }
// `simple` is a boolean that will return only the stars if true
// Ex: getBasePriceHeadlineValues(4, true) => { stars: '**' }
// If basePriceHeadline is less than 2 or equal to 3, it will be set to 2
export function getBasePriceHeadlineValues(basePriceHeadline: number, simple = false) {
  if (basePriceHeadline < 2 || basePriceHeadline === 3) basePriceHeadline = 2;
  const sentence = `prix par personne calculé sur une base de ${basePriceHeadline} voyageurs.`;
  if (basePriceHeadline === 4) {
    return !simple ? { stars: '**', sentence } : { stars: '**' };
  } else if (basePriceHeadline >= 5) {
    return !simple
      ? {
          stars: '***',
          sentence: `prix par personne calculé sur une base de 5 voyageurs et plus.`
        }
      : { stars: '***' };
  } else {
    return !simple ? { stars: '*', sentence } : { stars: '*' };
  }
}
